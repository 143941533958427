import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { object, string, mixed } from "yup";
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { MuiFileInput } from "mui-file-input";
import { useLocation } from "react-router-dom";

import { useSurveys } from "../hooks/Services/useSurveys";
import Scrollbar from "../components/scrollbar";
import AlertSnackbar from "../components/alert/AlertSnackbar";
import CarCroqui from "../components/carCroqui";
// ----------------------------------------------------------------------

export default function ReplySurvey() {
  const { replySurvey, message, setMessage, isLoading } = useSurveys();
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const props = location.state;

  // ========================================================================
  const [formValues, setFormValues] = useState({
    brand: props.brand || "",
    model: props.model || "",
    year: props.year || "",
    color: props.color || "",
    chassis: props.chassis || "",
    additional: props.additiona || "",
  });

  // vehiclePics
  const [fileDocs, setFileDocs] = useState({});
  const [vehiclePics, setVehiclePics] = useState({});

  const handleInputChange = (name, event) => {
    setFileDocs((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const handleInputChangeVehiclePic = (name, event) => {
    setVehiclePics((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  // Lógica necessária para validação do formulário
  const handleAlert = () => {
    setShowAlert(false);
    setMessage((prev) => {
      return { ...prev, show: false };
    });
  };

  useEffect(() => {
    if (message.message !== "" && message.show) {
      setShowAlert(true);
    }
  }, [message]);

  const replySurveySchema = object().shape({
    formValues: object().shape({
      brand: string().required("A marca é necessária"),
      model: string().required("O modelo é necessário"),
      color: string().required("A cor é necessária"),
      year: string().required("O ano é necessário"),
      // chassis: string().required('O chassi é necessário'),
    }),
    fotos: object().shape({
      vehiclePics: object().shape({
        vehiclePics: mixed().required("Fotos do veículo são necessárias"),
      }),
      fileDocs: object().shape({
        docVehicle: mixed().required(
          "Foto do documento do veículo é necessária"
        ),
      }),
    }),
  });

  const handleSubmit = (dataReply) => {
    replySurveySchema
      .validate(dataReply, { abortEarly: false })
      .then(() => {
        replySurvey(dataReply);
      })
      .catch((error) => {
        setMessage({ message: error.errors[0], status: 500, show: true });
      });
  };

  return (
    <>
      <Helmet>
        <title>Nova Vistoria</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Criar um chamado
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Sobre o veículo</Typography>
              <Box className="d-flex mb-1">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      variant="outlined"
                      className="w-100"
                      disabled
                      name="brand"
                      value={formValues.brand || ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      variant="outlined"
                      className="w-100"
                      disabled
                      name="model"
                      value={formValues.model || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      variant="outlined"
                      className="w-100"
                      disabled
                      name="year"
                      value={formValues.year || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      variant="outlined"
                      className="w-100"
                      disabled
                      name="color"
                      value={formValues.color || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props.plate || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      variant="outlined"
                      className="w-100"
                      disabled
                      name="chassis"
                      value={formValues.chassis || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      variant="outlined"
                      className="w-100"
                      name="additional"
                      value={formValues.additional || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      variant="outlined"
                      className="w-100"
                      value={props.cod || ""}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            {props?.positionsChecked && (
              <CarCroqui positionsChecked={props?.positionsChecked} />
            )}

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Fotos obrigatórias</Typography>
              <Box className="d-flex mb-1">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} md={3}>
                    <MuiFileInput
                      value={vehiclePics.vehiclePics || null}
                      onChange={(e) =>
                        handleInputChangeVehiclePic("vehiclePics", e)
                      }
                      placeholder={"Fotos do veículo"}
                      multiple
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className="d-flex mb-1 mt-2">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={3} md={3}>
                    <MuiFileInput
                      value={fileDocs.docVehicle || null}
                      onChange={(e) => handleInputChange("docVehicle", e)}
                      placeholder={"Documentações"}
                      multiple
                    />
                  </Grid>
                </Grid>
              </Box>
              <LoadingButton
                onClick={() => {
                  handleSubmit({
                    formValues,
                    fotos: { fileDocs, vehiclePics },
                    survey: props.id,
                  });
                }}
                className="w-100 mb-1 mt-5"
                variant="outlined"
                loading={isLoading}
              >
                Finalizar
              </LoadingButton>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4"></Card>
          </Scrollbar>
        </Card>

        <AlertSnackbar
          openAlert={showAlert}
          duration={2500}
          status={message.status}
          message={message.message}
          onClose={handleAlert}
        />
      </Container>
    </>
  );
}
