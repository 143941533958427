import { Helmet } from "react-helmet-async";
import { useState } from "react";
// @mui
import {
  Card,
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Paper,
} from "@mui/material";
// components
import { useLocation } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ReactViewer from "react-viewer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Icon } from "@iconify/react";
import Scrollbar from "../components/scrollbar";
import TimeLine from "src/components/timeline";
import CarCroqui from "../components/carCroqui";

// ----------------------------------------------------------------------

export default function ViewSurveys() {
  const location = useLocation();
  const props = location.state;

  const vehiclePhotosRefArray = ["vehiclePics", "dealerVehiclePics"];

  const vehicleDocsPhotosRefArray = ["docVehicle", "dealerDocVehicle"];

  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openViewer = (src) => {
    setCurrentImage(src);
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  return (
    <>
      <Helmet>
        <title>Detalhes</title>
      </Helmet>

      <Container>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <TimeLine status={props?.status} />
          <Typography variant="h4" gutterBottom className="w-100 mt-5">
            Detalhes
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sx={{ marginBottom: "0.5rem" }}>
                    <Typography variant="h6">Endereço</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Rua"
                      disabled
                      value={props?.deliveryStreet}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Bairro"
                      disabled
                      value={props?.deliveryDistrict}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      label="Número"
                      disabled
                      value={props?.deliveryNumber}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cidade"
                      disabled
                      value={props?.deliveryCity}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      label="CEP"
                      disabled
                      value={props?.deliveryCep}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      label="Estado"
                      disabled
                      value={props?.deliveryState}
                      variant="outlined"
                      className="w-100"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Typography variant="h6">Veículo</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.brand || ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.model || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.year || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.color || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.plate || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.chassis || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.additional || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.cod || ""}
                    />
                  </Grid>
                  {props?.notes?.bcNotesToDealer && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações da Impar"
                        disabled
                        variant="outlined"
                        className="w-100"
                        value={props?.notes?.bcNotesToDealer || ""}
                      />
                    </Grid>
                  )}
                  {props?.notes?.dealerNotes && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações"
                        disabled
                        variant="outlined"
                        className="w-100"
                        value={props?.notes?.dealerNotes || ""}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12} className="text-center">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={props.armored} />}
                      label="Veículo Blindado"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            {props?.positionsChecked && (
              <CarCroqui positionsChecked={props?.positionsChecked} />
            )}

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Foto do veículo
              </Typography>
              <Carousel>
                {props.photos ? (
                  props.photos
                    .filter((photo) =>
                      vehiclePhotosRefArray.includes(photo.ref)
                    )
                    .map((photo, index) => (
                      <div key={index}>
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            padding: 0,
                          }}
                          onClick={() => openViewer(index)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              openViewer(index);
                            }
                          }}
                          aria-label={`Ver imagem ${index + 1}`}
                        >
                          <SideBySideMagnifier
                            imageSrc={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                            imageAlt={`Foto do veículo ${index + 1}`}
                            style={{ cursor: "pointer" }}
                          />
                        </button>
                      </div>
                    ))
                ) : (
                  <Typography variant="body2">
                    Nenhuma foto encontrada
                  </Typography>
                )}
              </Carousel>

              <ReactViewer
                visible={visible}
                onClose={closeViewer}
                images={props.photos
                  .filter((photo) => vehiclePhotosRefArray.includes(photo.ref))
                  .map((photo) => ({
                    src: `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                    alt: "Imagem do veículo",
                  }))}
                index={currentImageIndex}
                zIndex={1000}
              />
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Documentação
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {props.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehicleDocsPhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => {
                        const pdf = photo.foto.endsWith(".pdf");

                        return pdf ? (
                          <Grid item xs={4} md={4} key={index}>
                            <Icon
                              style={{ cursor: "pointer" }}
                              width={100}
                              icon="vscode-icons:file-type-pdf2"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                                  "_blank"
                                )
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={4} md={4} key={index}>
                            <img
                              width={200}
                              src={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              alt={photo.ref}
                              title={photo.ref}
                            />
                          </Grid>
                        );
                      })
                  ) : (
                    <Grid item xs={12}>
                      <Paper sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          Sem fotos anexadas
                        </Typography>
                        <Typography variant="body2">
                          Nenhuma foto dos documentos anexada
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
